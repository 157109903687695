import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from '../components/Section';
import MediaCards from '../components/MediaCards';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section mdxType="Section">
      <h2 {...{
        "id": "organizers"
      }}>{`Organizers`}</h2>
      <MediaCards mdxType="MediaCards">
        <img {...{
          "src": "https://user-images.githubusercontent.com/334891/45935813-4852c000-bf7d-11e8-8e97-8ae77ab85086.jpg",
          "alt": "Diana Mounter"
        }}></img>
        <h3 {...{
          "id": "diana-mounter"
        }}>{`Diana Mounter`}</h3>
        <p>{`Design Operations Manager at GitHub`}</p>
        <img {...{
          "src": "https://pbs.twimg.com/profile_images/807319789521825793/pVkgDhPm_400x400.jpg",
          "alt": "Chesley Andrews"
        }}></img>
        <h3 {...{
          "id": "chesley-andrews"
        }}>{`Chesley Andrews`}</h3>
        <p>{`Product Design Manager at Betterment`}</p>
        <img {...{
          "src": "https://pbs.twimg.com/profile_images/1130934092248342528/q7NQsSzz_400x400.jpg",
          "alt": "Matthew Ström"
        }}></img>
        <h3 {...{
          "id": "matthew-ström"
        }}>{`Matthew Ström`}</h3>
        <p>{`Design Lead at Bitly`}</p>
        <img {...{
          "src": "https://pbs.twimg.com/profile_images/746829844026114049/N-gpR7I4_400x400.jpg",
          "alt": "Tom Takigayama"
        }}></img>
        <h3 {...{
          "id": "tom-takigayama"
        }}>{`Tom Takigayama`}</h3>
        <p>{`Product Design Manager at Justworks`}</p>
        <img {...{
          "src": "https://user-images.githubusercontent.com/334891/45935737-6f5cc200-bf7c-11e8-8c59-d1b91ae43f70.jpg",
          "alt": "Brent Jackson"
        }}></img>
        <h3 {...{
          "id": "brent-jackson"
        }}>{`Brent Jackson`}</h3>
        <p>{`Software Developer at Gatsby`}</p>
      </MediaCards>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      