import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import SplitSection from '../components/SplitSection';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SplitSection sx={{
      fontSize: [4, 5],
      fontWeight: 'bold'
    }} mdxType="SplitSection">
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/50564896-162cc900-0cf7-11e9-8f67-1798bdb24c71.jpg",
        "alt": "DSC at Etsy"
      }}></img>
      <div>
        <h2 {...{
          "id": "host-or-speak-at-our-next-meetup"
        }}>{`Host or speak at our next meetup!`}</h2>
        <p><a parentName="p" {...{
            "href": "/get-involved",
            "title": "button"
          }}>{`Get Involved`}</a></p>
      </div>
    </SplitSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      