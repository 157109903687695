import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from '../components/Section';
import Cards from '../components/Cards';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section mdxType="Section">
      <h2 {...{
        "id": "speakers"
      }}>{`Speakers`}</h2>
      <Cards ratio={1} width={['100%', '50%', '25%']} mdxType="Cards">
        <img {...{
          "src": "https://user-images.githubusercontent.com/334891/65395177-44f73d80-dd65-11e9-9eab-3988ac30c8a2.jpeg",
          "alt": null
        }}></img>
        <h3 {...{
          "id": "rune-madsen"
        }}>{`Rune Madsen`}</h3>
        <p>{`Rune is a designer, artist, and educator who uses programming languages to create things with the computer. As a co-founder of Design Systems International, he specializes in code-based design projects including non-trivial interfaces, custom design tools, design systems, and dynamic branding projects. He is also the author of the book Programming Design Systems.`}</p>
        <p><a parentName="p" {...{
            "href": "https://twitter.com/runemadsen"
          }}>{`@runemadsen`}</a></p>
        <img {...{
          "src": "https://user-images.githubusercontent.com/334891/65395174-44f73d80-dd65-11e9-9bf9-7f4e8abc2c97.jpeg",
          "alt": null
        }}></img>
        <h3 {...{
          "id": "chelsea-otakan"
        }}>{`Chelsea Otakan`}</h3>
        <p>{`Chelsea is a product design manager at Stripe, supporting the design systems team. When not managing, designing, or writing code, she’s probably doing karaoke. Sometimes goes by Chex.`}</p>
        <p><a parentName="p" {...{
            "href": "https://twitter.com/chexee"
          }}>{`@chexee`}</a></p>
        <img {...{
          "src": "https://user-images.githubusercontent.com/334891/65395176-44f73d80-dd65-11e9-8bc9-05abd6971e66.jpeg",
          "alt": null
        }}></img>
        <h3 {...{
          "id": "max-haarhaus"
        }}>{`Max Haarhaus`}</h3>
        <p>{`Max is a Software Engineer at Justworks on the Design System Team, where he builds and maintains a library of components, design tokens, and developer utilities, and obsesses over editor color schemes.`}</p>
        <p><a parentName="p" {...{
            "href": "https://twitter.com/maxhaarhaus"
          }}>{`@maxhaarhaus`}</a></p>
        <img {...{
          "src": "https://user-images.githubusercontent.com/334891/65395175-44f73d80-dd65-11e9-8bc7-8febce7ae648.jpeg",
          "alt": null
        }}></img>
        <h3 {...{
          "id": "chris-carbo"
        }}>{`Chris Carbo`}</h3>
        <p>{`Chris is a Senior Product Designer at Justworks on the design system team. Chris believes the key differences between any good and great design system are designing for the right reasons, trusting the system, being vulnerable and pizza.`}</p>
        <p><a parentName="p" {...{
            "href": "https://twitter.com/carbonateddd"
          }}>{`@carbonateddd`}</a></p>
      </Cards>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      