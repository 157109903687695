import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from '../components/Section';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section id='next-event' sx={{
      maxWidth: '75%'
    }} mdxType="Section">
      <h2 {...{
        "id": "exploring-design-systems"
      }}>{`Exploring Design Systems`}</h2>
      <h3 {...{
        "id": "sept-25--justworks"
      }}>{`Sept 25 @ Justworks`}</h3>
      <img src='' />
      <p>{`In this meetup we'll explore different aspects of design systems. From trade-offs, to adoption within your organization, and how approaching design systems as tools might inform the way you build them.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/264428092/",
          "title": "button"
        }}>{`RSVP`}</a></p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      