import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from '../components/Section';
import Cards from '../components/Cards';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Section;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "past-events"
    }}>{`Past Events`}</h2>
    <Cards ratio={9 / 16} mdxType="Cards">
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/65395393-437b4480-dd68-11e9-8295-045f5c5abf00.jpeg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "dsl-x-nyc-design-systems-at-a-global-scale"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/DSLxNYC-Meetup/events/260450139/"
        }}>{`DSL x NYC: Design Systems at a global scale`}</a></h3>
      <p>{`May 28 @ Condé Nast`}</p>
      <p>{`In collaboration with `}<a parentName="p" {...{
          "href": "https://www.designsystemslondon.com"
        }}>{`DSL (Design Systems London)`}</a>{`. DSL x NYC design systems meetup gathers designers and engineers to share experiences, ideas, and approaches on how to design, build and maintain design systems.`}</p>
      <img {...{
        "src": "/2019-04-24-a.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "designing-for-scalability"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/260450846/"
        }}>{`Designing for Scalability`}</a></h3>
      <p>{`April 24 @ Reaktor`}</p>
      <p>{`Join us for this meetup with lightning talks focussed on designing for scalability—how do you prioritize, collaborate, design for different platforms, and build a design system that works for different needs and provide a great developer experience.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/45930307-7c51c500-bf2c-11e8-97ce-0e1c2231c76f.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "happy-hour"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/259942025/"
        }}>{`Happy Hour!`}</a></h3>
      <p>{`March 27 @ The Winslow`}</p>
      <p>{`Join us for a happy hour and meet other design systems professionals and enthusiasts. This event will give folks the opportunity to meet up in a casual setting.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/41512111-a336b338-7251-11e8-93b8-7371c8e50a39.JPG",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "happy-hour-1"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/257976364/"
        }}>{`Happy Hour!`}</a></h3>
      <p>{`Jan 23 @ The Winslow`}</p>
      <p>{`Join us for a happy hour and meet other design systems professionals and enthusiasts. This event will give folks the opportunity to meet up in a casual setting.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/50564566-e16b4280-0cf3-11e9-8804-1a57fb157aa0.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "shaping-design-systems"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/255728499/"
        }}>{`Shaping design systems`}</a></h3>
      <p>{`December 5 @ Datadog`}</p>
      <p>{`Featuring talks by Derek Howles from Datadog, Nicole Yeo from Artsy, and Carl Nelson.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/50576129-e1913e00-0dd8-11e9-8cb6-21df4b5803f6.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "october-happy-hour-sponsored-by-reaktor"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/254919672/"
        }}>{`October happy hour sponsored by Reaktor`}</a></h3>
      <p>{`October 25 @ The Storehouse`}</p>
      <p>{`A social event for us to chat over drinks and make new connections in the design systems community.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/47277051-0e7bd600-d58a-11e8-93ab-94857ada089e.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "putting-your-design-system-into-practice"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/254103852/"
        }}>{`Putting your design system into practice`}</a></h3>
      <p>{`Sept 27 @ MongoDB`}</p>
      <p>{`Featuring talks by Deep Shah from MongoDB, Marissa Christy from Paperless Post, and Laura Hahn from Priceline.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/45930307-7c51c500-bf2c-11e8-97ce-0e1c2231c76f.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "july-happy-hour-sponsored-by-mongodb"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/251864317/"
        }}>{`July happy hour sponsored by MongoDB`}</a></h3>
      <p>{`July 26 @ The Winslow`}</p>
      <p>{`A social event for us to chat over drinks and make new connections in the design systems community.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/43049316-62e7f4d0-8dc3-11e8-9a67-588147a71178.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "design-system-apis-and-the-developer-experience"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/251191026/"
        }}>{`Design system APIs and the developer experience`}</a></h3>
      <p>{`June 20 @ Betterment`}</p>
      <p>{`Featuring talks by Arielle Sullivan & Connor McNabb from Betterment, Stephanie Manwaring from XO Group, and Jenn Creighton from ClassPass.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/41512111-a336b338-7251-11e8-93b8-7371c8e50a39.JPG",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "may-happy-hour"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/249908974/"
        }}>{`May happy hour`}</a></h3>
      <p>{`May 23 @ The Winslow`}</p>
      <p>{`A social event for us to chat over drinks and make new connections in the design systems community.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/43049909-f3c894d2-8dcd-11e8-9722-298088313767.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "bridging-the-gap"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/247693556/"
        }}>{`Bridging the gap`}</a></h3>
      <p>{`April 19 @ ustwo`}</p>
      <p>{`Featuring talks by Pedro Sepulveda from ustwo, Mike Perrotti from Meetup, and Fabian Perez from GitHub.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/38999259-66174c58-43bf-11e8-8ee2-4ec1a6fc136c.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "tools-for-collaboration"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/247534331/"
        }}>{`Tools for collaboration`}</a></h3>
      <p>{`March 8 @ Meetup`}</p>
      <p>{`Featuring talks by Noah Levin from Figma, Natalya Shelburne from The New York Times, Brent Jackson from Priceline.com, and Chesley Andrews from Betterment.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/36069622-7d3d42fe-0ebb-11e8-966e-7cd99aa1f925.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "february-happy-hour"
      }}><a parentName="h3" {...{
          "href": "https://www.meetup.com/NYC-Design-Systems-Coalition/events/247261426/"
        }}>{`February happy hour`}</a></h3>
      <p>{`Feb 7 @ The Winslow`}</p>
      <p>{`A social event for us to chat over drinks and make new connections in the design systems community.`}</p>
      <img {...{
        "src": "https://user-images.githubusercontent.com/334891/35812571-627904d6-0a5f-11e8-942a-8e0f7e28af58.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "defining-design-principles"
      }}><a parentName="h3" {...{
          "href": "https://youtu.be/VtTbIXnq24k"
        }}>{`Defining design principles`}</a></h3>
      <p>{`Oct 4 @ The New York Times`}</p>
      <p>{`Featuring talks by Orr Shtuhl from Wirecutter, Caitlin Osbahr from Buzzfeed, Michael Jovel from the F.D.A., and Magera Holton from Related Works.`}</p>
      <img {...{
        "src": "/2017-08-DSC-DoSomething-7739.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "empowering-product-growth-with-design-systems"
      }}><a parentName="h3" {...{
          "href": "/2017-august"
        }}>{`Empowering product growth with design systems`}</a></h3>
      <p>{`Aug 17 @ DoSomething`}</p>
      <p>{`Featuring talks by Una Kravets from Digital Ocean, Luke Patton from DoSomething, and Jina Anne—consultant and community builder.`}</p>
      <img {...{
        "src": "/may-speaker-photo2.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "documenting-design-systems"
      }}><a parentName="h3" {...{
          "href": "/2017-may"
        }}>{`Documenting design systems`}</a></h3>
      <p>{`May 11 @ JustWorks`}</p>
      <p>{`Featuring talks by Tom Takigayama from Justworks, Adekunle Oduye from Nasdaq, and Amélie Lamont from The New York Times.`}</p>
      <img {...{
        "src": "/DSC-02Etsy-4469.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "brand-design-systems-that-scale"
      }}><a parentName="h3" {...{
          "href": "/2017-march"
        }}>{`Brand design systems that scale`}</a></h3>
      <p>{`March 23 Etsy @ HQ`}</p>
      <p>{`Featuring talks by Sophie Shepherd from GitHub, Will Miner from 2U, and Marco Suarez from Etsy.`}</p>
      <img {...{
        "src": "/video-thumbnail.jpg",
        "alt": null
      }}></img>
      <h3 {...{
        "id": "sharing-design-systems-journeys"
      }}><a parentName="h3" {...{
          "href": "/2016-december"
        }}>{`Sharing design systems journeys`}</a></h3>
      <p>{`December 15 XO @ Group`}</p>
      <p>{`Featuring Emily Brick from Buzzfeed, Silvia Tueros-Cossio and Philip Kelly from XO Group, and Diana Mounter from GitHub.`}</p>
    </Cards>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      