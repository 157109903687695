import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Section from '../components/Section';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section sx={{
      fontSize: [4, 5],
      fontWeight: 'bold'
    }} mdxType="Section">
      <p>{`We’re looking for speakers for future meetups!`}</p>
      <p><a parentName="p" {...{
          "href": "https://t.co/nWT3FMTXCy"
        }}>{`Submit a talk →`}</a></p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      