import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Header from '../sections/header.mdx';
import Hero from '../sections/hero.mdx';
import NextEvent from '../sections/next-event.mdx';
import Speakers from '../sections/speakers.mdx';
import CFP from '../sections/cfp.mdx';
import PastEvents from '../sections/past-events.mdx';
import HostPromo from '../sections/host-promo.mdx';
import Organizers from '../sections/organizers.mdx';
import Footer from '../sections/footer.mdx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header mdxType="Header" />
    <Hero mdxType="Hero" />
    <NextEvent mdxType="NextEvent" />
    <Speakers mdxType="Speakers" />
    <CFP mdxType="CFP" />
    <PastEvents mdxType="PastEvents" />
    <HostPromo mdxType="HostPromo" />
    <Organizers mdxType="Organizers" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      