import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Banner from '../components/Banner';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Banner mdxType="Banner">
      <h1 {...{
        "id": "design-systems-coalition"
      }}>{`Design Systems Coalition`}</h1>
      <h2 {...{
        "id": "nyc"
      }}>{`NYC`}</h2>
      <p>{`A community for people who build design systems to share and learn from each other`}</p>
    </Banner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      